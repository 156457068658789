import SwaggerUI from 'swagger-ui-react';
import { Select, Text } from '@chakra-ui/react';
import { useState } from 'react';

function App() {
  // const docUrls = ['http://localhost:5555/api/player/docs', 'http://localhost:5555/api/payment/docs']; // для тестов
  const docUrls = [
    'https://wincity.games/api/player/docs',
    'https://wincity.games/api/payment/docs',
  ];
  const [docUrl, setDocUrl] = useState(docUrls[0]);

  return (
    <>
      <Text fontSize="2xl">Выберите проект:</Text>
      <Select
        onChange={(el) => {
          setDocUrl(el.target.value);
        }}
      >
        {docUrls?.map((url) => {
          return <option value={url}>{url}</option>;
        })}
      </Select>
      <SwaggerUI url={docUrl} />
    </>
  );
}

export default App;
